<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <a-button
        type="primary"
        class="back-btn"
        @click="$router.push({ name: 'ministry' })"
      >
        <i class="t-transition icons icon-chevron-left" />
        Назад
      </a-button>

      <a-row class="post-search" type="flex">
        <a-select
          v-model="perPage"
          class="table__parpage"
          option-filter-prop="children"
          placeholder="Все"
          @change="tableperPage"
        >
          <a-select-option
            v-for="(limit, index) in LimitList"
            :key="index"
            :value="limit.value"
          >
            {{ limit.title }}
          </a-select-option>
        </a-select>

        <a-button
          type="primary"
          class="all-news-btn"
          style="margin: 0 15px"
          @click="Allnews"
        >
          Все
        </a-button>

        <a-button
          type="primary"
          @click="$router.push({ name: 'certificates-create' })"
        >
          <a-icon type="plus" />{{ $t("Add") }}
        </a-button>
      </a-row>

      <a-row type="flex" style="width: 100%; justify-content: space-between">
        <h1>{{ title[$i18n.locale] }}</h1>
        <a-button
          :disabled="cardsIsEmpty"
          type="danger"
          @click="removeItem(selectedPosts)"
        >
          <a-icon class="action-btns" type="delete" />
          <!--{{ $t("Delete") }}-->
        </a-button>
      </a-row>
    </a-row>

    <!-- <a-row>
        <a-col :span="6" class="sm-mb">
          <a-input
            v-model="search"
            placeholder="Поиск сотрудника..."
            @input="SearchInput"
          />
        </a-col>
      </a-row> -->
    <!-- {{tableProps.results}} -->
    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :loading="loading"
      :data-source="tableProps.results"
      :pagination="false"
      :scroll="{ y: 600 }"
      :row-key="(record) => record.id"
    >
      <template slot="cert_registered_date" slot-scope="item">
        {{ moment(item).format("DD.MM.YYYY") }}
      </template>

      <template slot="cert_validity_date" slot-scope="item">
        {{ moment(item).format("DD.MM.YYYY") }}
      </template>
      <template slot="operation" slot-scope="item">
        <div class="action">
          <a-button
            class="edit-btn"
            type="primary"
            @click="
              $router.push(
                {
                  name: 'certificates-detail',
                  params: { id: item.id }
                },
                () => null
              )
            "
          >
            <a-icon class="action-btns" type="edit" />
          </a-button>

          <a-button type="danger" @click="removeItem(item.id)">
            <a-icon class="action-btns" type="delete" />
          </a-button>
        </div>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :current="page"
        :default-page-size="perPage"
        :total="+tableProps.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      selectedRowKeys: [],
      selectedPosts: [],
      columns: [
        {
          title: "T/r",
          key: "order",
          dataIndex: "order",
          align: "center",
          width: "80px",
          scopedSlots: { customRender: "order" }
        },
        {
          title: "F.I.Sh.",
          key: "full_name",
          dataIndex: "full_name",
          align: "left",
          scopedSlots: { customRender: "full_name" }
        },
        {
          title: "Sertifikatga oid ma'lumotlar",
          children: [
            {
              title: "Sertifikat raqami",
              key: "cert_number",
              dataIndex: "cert_number",
              align: "center",
              scopedSlots: { customRender: "cert_number" }
            },
            {
              title: "Sertifikat sanasi",
              key: "cert_registered_date",
              dataIndex: "cert_registered_date",
              align: "center",
              scopedSlots: { customRender: "cert_registered_date" }
            },
            {
              title: "Amal qilish muddati",
              key: "cert_validity_date",
              dataIndex: "cert_validity_date",
              align: "center",
              scopedSlots: { customRender: "cert_validity_date" }
            }
          ]
        },
        {
          title: "Malaka oshirishga oid ma'lumotlar",
          children: [
            {
              title: "Guvohnoma raqami",
              key: "licence_number",
              dataIndex: "licence_number",
              align: "center",
              scopedSlots: { customRender: "licence_number" }
            },
            {
              title: "Guvohnoma sanasi",
              key: "licence_registered_date",
              dataIndex: "licence_registered_date",
              align: "center",
              scopedSlots: { customRender: "licence_registered_date" }
            }
          ]
        },
        {
          title: this.$t("TableAction"),
          key: "operation",

          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      search: "",
      tableProps: {
        results: [],
        count: 1
      },
      title: {
        uz: "Ichki audit milliy malaka sertifikatlari reestri",
        oz: "Ички аудит миллий малака сертификатлари рэстри",
        ru: "Перечень национальных квалификационных сертификатов внутреннего аудита",
        en: "List of internal audit national qualification certificates"
      },
      LimitList: [
        {
          value: 10,
          title: "10"
        },
        {
          value: 20,
          title: "20"
        },
        {
          value: 40,
          title: "40"
        }
      ],
      perPage: 20,
      page: 1,
      cardsIsEmpty: true,
      offset: 0
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item === +itemId)
      if (f !== -1) {
        this.selectedPosts.splice(f, 1)
        this.cardsIsEmpty = false
      } else {
        this.selectedPosts.push(itemId)
        this.cardsIsEmpty = false
      }

      if (this.selectedPosts.length === 0) {
        this.cardsIsEmpty = true
      }
      console.log(this.selectedPosts)
    },
    tableperPage() {
      this.offset = 0
      this.fetchData()
    },
    async Allnews() {
      this.loading = true
      this.perPage = null
      this.categoryProps = {
        results: [],
        count: 0
      }
      try {
        const r = await this.$store.dispatch("certificates/fetch", {
          type: 0
        })
        if (r) {
          this.$set(this, "tableProps", {
            results: r || []
          })
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
      this.loading = false
    },
    toPage(arg) {
      this.$router.push({ query: { page: arg } })
    },
    SearchInput() {
      this.offset = 0
      this.fetchData()
    },
    async fetchData() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.perPage
      try {
        const data = await this.$store.dispatch("certificates/fetch", {
          limit: this.perPage,
          offset: this.offset,
          search: this.search,
          type: 0,
          // is_active: false
        })
        if (data) {
          this.$set(this, "tableProps", {
            results: data.results || [],
            count: data.count
          })
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }

      this.loading = false
    },
    // async removeItem(id) {

    //     try {
    //       const res = await this.$store.dispatch("certificates/removeItemAction", id)
    //       if (res && res.status === 204) {
    //         this.$message.success("Успешно удалено")
    //         this.fetchData()
    //       }
    //     } catch (e) {
    //       this.$sentry.captureMessage(e)
    //     }
    //   this.selectedCards = []
    //   this.cardsIsEmpty = true
    // },

    async removeItem(id) {
      try {
        // Dispatch the Vuex action to delete the item
        const res = await this.$store.dispatch(
          "certificates/removeItemAction",
          id
        )

        // Check the API response and display appropriate feedback
        if (res && res.status === 204) {
          this.$message.success("Успешно удалено") // Success message
          await this.fetchData() // Refresh the table to reflect changes
        } else {
          this.$message.error("Не удалось удалить элемент. Повторите попытку.") // Error message
        }
      } catch (error) {
        // Log and display an error notification
        console.error(`Error deleting item with ID ${id}:`, error)
        this.$message.error("Произошла ошибка при удалении элемента.")
      }
    }

    // updatePosition(node, event) {
    //   this.loading = true
    //   this.$api
    //     .patch(`/admin/structure/leadership/${node.id}/update/`, {
    //       order: event.target.value
    //     })
    //     .then(() => {
    //       this.loading = false
    //       this.$message.success("Позиция обновлена")
    //       this.fetchData()
    //     })
    //     .catch((err) => {
    //       this.loading = false
    //       this.$message.error(err.message)
    //     })
    // }
  }
}
</script>

<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}

.custom-table .ant-table-thead > tr > th {
  text-align: center;
  background-color: #f5f5f5;
  font-weight: bold;
}
.custom-table .ant-table-cell {
  text-align: center;
}
.action {
  display: flex;
}
</style>
